/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

const serverHost = (import.meta.env.VITE_SERVER_HOST as string) || 'https://boilerplate.no'
const serverPort = import.meta.env.VITE_SERVER_PORT as string

let serverBaseUrl = `${serverHost}`

// Local development hosting requires the port for "hot reload" and for mobile app to server access over LAN
if (
  import.meta.env.DEV &&
  serverHost &&
  (serverHost.includes('localhost') || serverHost.includes('127.0.0.1'))
) {
  serverBaseUrl = `${serverHost}:${serverPort}`
}

const userRolesString = 'user,monitor,admin,logs'
const userRoles = userRolesString.split(',')

let appVersion: string = document.documentElement.getAttribute('data-appversion') || ''

// The web-based app will always be the latest version, set the version directly from .env
// If not built with Cordova, 'data-appversion' will === '%%VERSION%%'
if (appVersion === '%%VERSION%%') appVersion = (import.meta.env.VITE_VERSION as string) || ''

const deviceType: string = window.location.protocol == 'file:' ? 'mobile' : 'web'

// User roles determine what is displayed on screen UI
enum USER_ROLE {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
  logs = 'logs'
}
// Ensure enums match those defined in env file
const t = Object.values(USER_ROLE)
if (
  !userRoles.every((e: string) => t.indexOf(e as USER_ROLE) > -1) ||
  userRoles.length !== t.length
) {
  console.error('USER_ROLE enum mismatch', { t, userRoles })
}

enum LanguageCodes {
  iv = 'iv', // Invariant code
  nn = 'nn',
  no = 'no',
  // en = 'en', // english is not currently supported
  sv = 'sv'
}
// The keys used for i18n selection (except for 'system') should correlate to LanguageCodes (used for Squidex GraphQL calls)
enum LanguageNames {
  system = 'system', // System default
  //ar = 'العربية', // Arabic
  //bs = 'bosanski', // Bosnian
  //nb_NO = 'Norsk Bokmål',
  nn = 'Nynorsk',
  no = 'Norsk',
  sv = 'Svensk'
  /*  nn_NO = 'Norsk Nynorsk', */
  // en = 'English',
  //ku = 'Soranî', // Kurdish
  //pl = 'Polski', // Polish
  //so = 'af Soomaali', // Somali
  /*   sq = 'shqip', // Albanian */
  //ta = 'தமிழ்', // Tamil
  //tr = 'Türkçe',
  //ur = 'اردو', // Urdu
  /*  vi = 'tiếng việt', // Vietnamese */
}

interface LF {
  [key: string]: Array<LanguageCodes>
}

interface Phase {
  key: string
  start: number
  end: number
  text: string
}
interface Highlight {
  key: string
  start: number
  text: string
}

// Note that every language must have a fallback defined!
const LanguageFallbacks: LF = {
  en: [LanguageCodes.no],
  nn: [LanguageCodes.no],
  no: [],
  sv: [LanguageCodes.no]
}

// --------------- CMS ------------------
export const cmsUrl: string = import.meta.env.VITE_APP_CMS_HOST || ''
export const cmsClientId = import.meta.env.VITE_APP_SQUIDEX_CLIENTid || ''
export const cmsClientSecret = import.meta.env.VITE_APP_SQUIDEX_CLIENT_SECRET || ''

// --------------- API -----------------

export enum XHR_REQUEST_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

export enum XHR_CONTENT_TYPE {
  TEXT = 'text/html',
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded'
}

// Augment the Error class with message and status
export class HttpException extends Error {
  status: number
  message: string
  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }
}

export enum APIResponseCode {
  Ok = 200,
  Created = 201,
  NoContent = 204,

  Found = 302,
  NotModified = 304,

  BadRequest = 400,
  UnAuthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Gone = 410,
  TooLarge = 413
}

export const acceptedStates = [...Object.values(APIResponseCode)]
export const successStates = [
  APIResponseCode.Ok,
  APIResponseCode.Created,
  APIResponseCode.NoContent
]

export enum ErrorCode {
  LOGIN = '000',
  USER = '010',
  SQUIDEX = '180'
}

export enum PipelineStatus {
  Copying = 'Copying',
  Original = 'Original',
  Cleaned = 'Cleaned',
  Ready = 'Ready'
}

export {
  deviceType,
  serverBaseUrl,
  userRoles,
  USER_ROLE,
  appVersion,
  LanguageCodes,
  LanguageNames,
  LanguageFallbacks,
  type Phase,
  type Highlight
}
