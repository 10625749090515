<template>
  <div class="flex p-1 rounded bg-gray-300 justify-center">
    <div class="w-5" v-if="props.entry.category == SegmentCode.subtitle">
      <img :src="SubtitleIcon" />
    </div>
    <div class="w-4" v-else-if="props.entry.category == SegmentCode.highlight">
      <img :src="HighlightIcon" />
    </div>
    <div class="w-3" v-else-if="props.entry.category == SegmentCode.phase">
      <img :src="PhaseIcon" />
    </div>
    <div v-else>{{ t('unknowncategory') }}: {{ $props.entry.category }}</div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import type { SegmentEntry } from '@database'
import { SegmentCode } from '@models'

import SubtitleIcon from '@/assets/icons/fontawesome/closed-captioning-solid.svg'
import HighlightIcon from '@/assets/icons/fontawesome/star-solid.svg'
import PhaseIcon from '@/assets/icons/fontawesome/bookmark-solid.svg'

const props = defineProps({
  entry: { type: Object as PropType<SegmentEntry>, required: true }
})

const { t } = useI18n()
</script>
