<template>
  <div class="w-full full-height flex flex-col p-7 bg-gray-800">
    <ComponentHeader name="dashboard" />
    <div class="rounded-xl flex flex-wrap justify-start">
      <a
        v-for="(group, index) in userStore.getters.groups.value"
        :key="index"
        class="flex bg-gray-200 p-5 rounded-xl mr-3 mb-3"
        :class="groupStyles(group)"
        @click="selectGroup(group)"
      >
        <div class="flex flex-col items-start w-full">
          <h2 class="text-xl font-bold flex text-gray-700 capitalize mb-3">{{ group.name }}</h2>
          <div class="flex flex-col">
            <div v-for="(session, index) in group.sessions" :key="index" class="flex items-end">
              <p class="text-gray-700 mr-3">Session {{ index + 1 }}</p>
              <ModuleStatus :session="session" attribute="pipeline" :text="true" />
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useUserStore } from '@/composition/userStore'
import { useVisualisationStore } from '@/composition/visualisationStore'

import ModuleStatus from '@/components/analytics/ModuleStatus.vue'
import ComponentHeader from '@/components/ComponentHeader.vue'

import { PipelineStatus } from '@models'
import type { Group } from '@frontend'
import useDialogStore from '@/composition/dialog'
import { DialogMessageType } from '@/interfaces'

const router = useRouter()
const userStore = useUserStore()
const dialogStore = useDialogStore()
const visualisationStore = useVisualisationStore()

const messages = {
  no: {
    nosessions: 'Ingen sessions tilgjengelig for valgt gruppe'
  },
  en: {
    nosessions: 'No sessions available for chosen group'
  }
}

const { t } = useI18n({ messages })

onMounted(async () => {
  await userStore.actions.getGroupsForUser()
})

async function selectGroup(group: Group) {
  if (group.sessions.length > 0) {
    const session = group.sessions[group.sessions.length - 1]
    if (
      [PipelineStatus.Cleaned, PipelineStatus.Ready].some(
        (state) => session.status.pipeline === state
      )
    ) {
      visualisationStore.actions.setSelectedGroup(group)
      visualisationStore.actions.setSelectedSession(session)
      router.push({ name: 'Analytics', query: { group: group.name } })
    } else dialogStore.actions.pushMessage(t('processingnotdone'), DialogMessageType.Warning)
  } else {
    dialogStore.actions.pushMessage(t('nosessions'), DialogMessageType.Error)
  }
}

const groupStyles = (group: Group) => {
  return group.sessions.length === 0 ? 'bg-red-200' : ''
}
</script>

<style scoped></style>
