<script setup lang="ts">
import { onMounted } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'

import { useUserStore } from '@/composition/userStore.js'

import DialogManager from '@/components/dialog/DialogManager.vue'

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

onMounted(async () => {
  const validLogin = await userStore.actions.isValidSession()
  if (
    (!route.name || ['Postlogin', 'Dashboard', 'Analytics'].find((name) => name === route.name)) &&
    !validLogin
  ) {
    return router.push({ name: 'Login' })
  }
})
</script>

<template>
  <div class="w-full h-full flex flex-col justify-center">
    <div class="bg-white flex flex-col text-center items-center">
      <DialogManager />
      <RouterView />
    </div>
  </div>
</template>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
