<template>
  <div class="h-1/6 w-full flex flex-col justify-center items-start">
    <h1 class="text-5xl font-black text-gray-400">{{ t(`${props.name}`) }}</h1>
    <div class="bg-green-400 h-1 w-11 my-1 rounded"></div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps({
  name: { type: String, required: true }
})

const { t } = useI18n()
</script>
