<template>
  <div class="w-full full-height flex items-center justify-center flex-col bg-gray-800">
    <router-link
      ref="props.button.buttonLink"
      to="/"
      class="absolute left-5 top-5 flex justify-center bg-gray-400 cursor-pointer p-3 m-3 rounded-full"
    >
      <img class="-ml-px w-4 h-4 invert" :src="Arrow" />
    </router-link>
    <div class="bg-gray-400 rounded-xl p-5 flex justify-center items-center w-1/3">
      <div class="p-3 rounded-xl w-full h-full">
        <div class="p-3">
          <h1 class="text-2xl text-white font-bold mb-11">{{ t('login') }}</h1>
          <div
            v-for="(button, index) in authMethods"
            :key="index"
            class="flex bg-white rounded-xl h-20 cursor-pointer my-1"
          >
            <a class="w-full flex items-center" @click="login(button)">
              <div class="flex justify-center items-center">
                <img
                  :key="index"
                  :src="button.icon"
                  :alt="button.name"
                  class="mx-5 p-1 w-11 h-11"
                />
                <div class="text-xl text-black">
                  {{ button.label }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { DialogMessageType, LoginType, type AuthType } from '@/interfaces'

import { useUserStore } from '@/composition/userStore'

import useApiService from '@/api/apiRequest.js'
import useDialogStore from '@/composition/dialog'

import Arrow from '@/assets/icons/angle-left-solid.svg'

import Feide from '@/assets/icons/feide-icon.svg'
import { serverBaseUrl } from '@/constants'

const route = useRoute()
const router = useRouter()
const apiService = useApiService()
const dialogStore = useDialogStore()
const userStore = useUserStore()

const messages = {
  no: {
    anonymous: 'Delta med PIN',
    missingpin: 'Vennligst oppgi en PIN kode før du fortsetter',
    wrongpin: 'Vennligst velg en annen PIN og prøv igjen'
  },
  en: {
    anonymous: 'Join with PIN',
    missingpin: 'Please enter a PIN code before you proceed',
    wrongpin: 'Please choose another PIN and try again'
  }
}

const { t } = useI18n({ messages })

const authMethods = [
  {
    label: 'FEIDE',
    type: LoginType.Feide,
    url: `${serverBaseUrl}/auth/${LoginType.Feide}`,
    serviceName: 'feide',
    icon: Feide,
    name: 'Feide icon'
  }
] as AuthType[]

onMounted(async () => {})

function redirectToAuth(authType: AuthType) {
  window.location.href = authType.url
}

async function login(authType: AuthType) {
  redirectToAuth(authType)
}
</script>

<style scoped></style>
