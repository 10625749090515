import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { serverBaseUrl } from '@/constants.js'
import AboutView from '@/views/AboutView.vue'
import LoginView from '@/views/LoginView.vue'
import DashboardView from '@/views/DashboardView.vue'
import AnalyticsView from '@/views/AnalyticsView.vue'
import { useUserStore } from '@/composition/userStore.js'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  }
]

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/postlogin',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: AnalyticsView
  }
]

// Apparently Cordova does not work in browser 'history' mode
const isRunningOnELServer = serverBaseUrl.includes('engagelab') || serverBaseUrl.includes('uvapps')
const baseURL = isRunningOnELServer ? import.meta.env.BASE_URL : '/'
const routerType = createWebHistory(baseURL)
const userStore = useUserStore()

const router = createRouter({
  history: routerType,
  routes: [...routes, ...authRoutes]
})

router.beforeEach(async (to, from) => {
  const validLogin = await userStore.actions.isValidSession()
  if (authRoutes.find((route) => route.name === to.name) && !validLogin) {
    router.push({ name: 'Login' })
  }
})

export default router
