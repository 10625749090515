<!-- Copyright 2020, 2021 Richard Nesnass, Sharanya Manivasagam and Ole Smørdal

 This file is part of VIVA.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col justify-center items-center w-full p-5">
    <Loader v-if="!moduleIsReady" />
    <div v-show="moduleIsReady" class="flex flex-col w-full relative rounded-xl">
      <div class="flex justify-center flex-grow relative h-full">
        <video
          muted
          class="flex flex-grow"
          :class="state.fullScreenMode ? 'playbackVideo' : 'playbackVideoSmall'"
          ref="videoElement"
          id="videoElement"
          oncontextmenu="return false;"
          playsinline
          webkit-playsinline
          preload="metadata"
          :onloadedmetadata="setPlayerHeight"
        ></video>
        <audio ref="audioElement" id="audioElement"></audio>
        <div
          class="bg-gray-100 text-black w-48 h-full flex items-center transition-maxheight duration-500 ease-in-out"
          :class="[state.openCard ? 'max-w-full' : 'max-w-0']"
          :style="`height: ${state.playerHeight}px;`"
        >
          <slot name="transcript"></slot>
        </div>
        <div class="absolute right-2 top-2 z-20">
          <div
            class="flex items-center justify-center w-10 h-10 p-3 rounded-full border-black bg-white opacity-45 border-opacity-40 border-2 transform transition duration-500"
            :class="{ 'rotate-180 border-black': state.openCard }"
            @click.stop="(state.openCard = !state.openCard), setPlayerHeight()"
          >
            <img :src="arrowTopSVG" alt="arrow-button" />
          </div>
        </div>
      </div>

      <div class="bg-gray-100 mt-6 flex flex-col w-full">
        <div class="pt-11 pb-4 flex flex-col px-6 items-center">
          <div class="w-full flex flex-col">
            <div
              class="h-10"
              @pointerdown="() => (state.pauseUpdates = true)"
              @pointerup="() => (state.pauseUpdates = false)"
            >
              <Slider
                class="top-3 left-0 w-full playing-slider"
                v-model="state.currentPlayerTime"
                :format="formatProgressTooltip"
                :max="scrubberMax"
                :min="scrubberMin"
                @change="adjustProgress"
              />
            </div>
            <div class="w-full h-6 mb-4 relative">
              <div
                v-for="phase in phases"
                :key="phase.key"
                class="absolute border border-black"
                style="background-color: #fffacd"
                :style="calculatePhaseSize(phase)"
                @click="jumpToTime(phase)"
              >
                <span class="text-black">{{ phase.text }}</span>
              </div>
              <div
                v-for="h in highlights"
                :key="h.key"
                class="absolute highlight-horizontal -top-2"
                :style="calculateHighlightLocation(h)"
              >
                <div class="relative w-10 h-10">
                  <div class="highlight-tooltip highlight-tooltip-top">
                    <span class="cursor-pointer" @click="jumpToTime(h)">{{ h.text }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div
                class="p-1 pl-2 flex items-center justify-center text-white font-serious font-medium bg-seez-grey-400 rounded-2xl rounded-r-none cursor-pointer"
              >
                <input
                  v-if="state.editingStartTime"
                  ref="startTimeInputRef"
                  class="bg-seez-grey-400 text-white text-xsv w-14"
                  :class="[state.incorrectStartTime ? 'text-red-600' : '']"
                  v-model="state.localStartTime"
                  @keyup.enter="validateTime()"
                />
                <p v-else class="text-xsv" @click="editStartTime()">
                  {{ formattedStartTime }}
                </p>
              </div>
              <div
                class="flex flex-col flex-grow justify-center px-4 border-t border-b border-black"
              >
                <Slider
                  class="trimming-slider"
                  v-model="state.trim"
                  :format="formatProgressTooltip"
                  :max="state.duration"
                  :min="0"
                  :tooltips="false"
                  @slide="adjustTrim"
                />
              </div>
              <div
                class="p-1 pr-2 flex items-center justify-center text-white font-serious font-medium bg-seez-grey-400 rounded-2xl rounded-l-none cursor-pointer"
              >
                <input
                  v-show="state.editingEndTime"
                  ref="endTimeInputRef"
                  class="bg-seez-grey-400 text-white text-xsv w-14"
                  :class="[state.incorrectEndTime ? 'text-red-600' : '']"
                  v-model="state.localEndTime"
                  @keyup.enter="validateTime()"
                />
                <div
                  v-show="!state.editingEndTime"
                  class="flex flex-col items-center"
                  @click="editEndTime()"
                >
                  <p class="text-xsv">
                    {{ formattedEndTime }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-between align-center w-full mt-7 mb-3">
            <div class="flex justify-center items-center">
              <div
                v-show="!state.playing"
                class="flex items-center justify-center w-10 h-10 rounded-full p-3 pl-4 mr-2 border border-black"
                @click.stop="startPlaying()"
              >
                <img :src="playButtonSVG" alt="play-button" class="invert" />
              </div>
              <div
                v-show="state.playing"
                class="flex items-center justify-center w-10 h-10 rounded-full p-3.5 mr-2 border border-black"
                @click.stop="pausePlaying()"
              >
                <img :src="pauseButtonSVG" alt="pause-button" class="invert" />
              </div>
              <div class="text-black">{{ playerTime }}</div>
            </div>
            <div class="flex w-32 h-6 mt-2 flex-row items-center">
              <div class="flex flex-grow">
                <Slider
                  class="volume-slider h-2 w-full"
                  orientation="horizontal"
                  direction="ltr"
                  :format="formatVolumeTooltip"
                  :step="0.01"
                  :min="0"
                  :max="1"
                  v-model="state.currentVolume"
                  @change="adjustVolume"
                />
              </div>
              <div class="flex w-6 h-6 ml-4">
                <img v-if="state.currentVolume > 0" :src="soundOnButtonSVG" alt="volumeOn-button" />
                <img v-else :src="soundOffButtonSVG" alt="volumeOff-button" />
              </div>
            </div>
            <div class="flex w-6 h-6 mt-2" @click.stop="toggleScreenMode()">
              <img :src="fullscreenButtonSVG" alt="fullscreen-button" class="invert" />
            </div>
          </div>
        </div>
      </div>
      <!--div class="p-5 bg-gray-100 mt-3 text-black">
        <ul id="chapters" class="overflow-scroll scroll-smooth flex justify-evenly">
          <li
            v-for="(phase, index) in state.chapters"
            :id="`chapter-${index}`"
            :key="index"
            @click="jumpToTime(phase)"
            class="mx-2 bg-gray-700 w-full h-11 flex justify-center items-center cursor-pointer"
          >
            <span class="font-bold text-white">{{ phase.text }}</span>
          </li>
        </ul>
      </div-->
      <!--div class="p-5 bg-gray-100 mt-3 text-black">
        <div class="flex justify-start items-center">
          <div
            v-for="(mode, index) in modes"
            :key="index"
            class="bg-gray-700 mr-2 rounded cursor-pointer"
            @click="setMode(mode.name)"
          >
            <p class="text-white py-px px-4">{{ t(mode.name) }}</p>
          </div>
        </div>
      </div-->
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref, computed, onMounted, reactive, onUnmounted, nextTick, watch } from 'vue'
//import { useI18n } from 'vue-i18n'
import { formattedTimeToSeconds, formatTime } from '@/utilities'
import { PipelineSubStatus, SegmentCode } from '@models'

import playButtonSVG from '@/assets/icons/video/play.svg'
import pauseButtonSVG from '@/assets/icons/video/pause.svg'
import fullscreenButtonSVG from '@/assets/icons/video/scale_up.svg'
import soundOnButtonSVG from '@/assets/icons/video/sound_on.svg'
import soundOffButtonSVG from '@/assets/icons/video/sound_off.svg'
import arrowTopSVG from '@/assets/icons/svg/arrow_top.svg'

import Loader from '@/components/Loader.vue'

import Slider from '@vueform/slider'

import { useVisualisationStore } from '@/composition/visualisationStore'
import type { Segment } from '@database'
import { serverBaseUrl, type Phase, type Highlight } from '@/constants'

const visualisationStore = useVisualisationStore()

//import Button from '@/components/base/Button.vue'
//import IconBase from '@/components/icons/IconBase.vue'
//import IconCross from '@/components/icons/IconCross.vue'

/* const messages = {
  no: {},
  en: {}
} */

interface State {
  duration: number
  chapters: Phase[]
  segments: Segment[]
  playerHeight: number
  highlightedSegmentIndex: number
  trim: number[]
  localStartTime: string
  localEndTime: string
  currentVolume: number
  currentPlayerTime: number

  fullScreenMode: boolean
  volumeMenu: boolean
  playing: boolean
  editingEndTime: boolean
  editingStartTime: boolean
  incorrectStartTime: boolean
  incorrectEndTime: boolean
  pauseUpdates: boolean
  openCard: boolean
}

// TODO: selectedVideo is a streamed buffer from the API...
const state: State = reactive({
  duration: 0,
  chapters: [] as Phase[],
  segments: [] as Segment[],
  playerHeight: 300,
  highlightedSegmentIndex: -1,
  trim: [0, 0],
  localStartTime: '',
  localEndTime: '',
  currentVolume: 0.5,
  currentPlayerTime: 0,

  fullScreenMode: false,
  volumeMenu: true,
  playing: false,
  editingEndTime: false,
  editingStartTime: false,
  incorrectStartTime: false,
  incorrectEndTime: false,
  pauseUpdates: false,
  openCard: false
})

const emit = defineEmits<{
  (e: 'timeupdate', time: number): void
  (e: 'trimupdate', trim: number[]): void
}>()

//const { t } = useI18n({ messages })
const videoElement: Ref<HTMLVideoElement | undefined> = ref()
const audioElement: Ref<HTMLAudioElement | undefined> = ref()
const startTimeInputRef = ref()
const endTimeInputRef = ref()

const selectedSession = visualisationStore.getters.selectedSession
const formattedStartTime = computed(() => formatTime(state.trim[0]))
const formattedEndTime = computed(() => formatTime(state.trim[1]))

const moduleIsReady = computed(() => {
  return (
    selectedSession.value?.status.modules.CameraAudio === PipelineSubStatus.Ready &&
    selectedSession.value?.status.modules.CameraVideo === PipelineSubStatus.Ready
  )
})

const formatVolumeTooltip = function (value: number) {
  return Math.floor(value * 100)
}
const formatProgressTooltip = function (value: number) {
  return formatTime(value, state.trim[0])
}

function setup() {
  loadPlayers()
  setPlayerHeight()
}

// Run setup on route change
onMounted(() => {
  setup()
  window.addEventListener('resize', setPlayerHeight)
})

onUnmounted(() => {
  window.removeEventListener('resize', setPlayerHeight)
})

// Run setup on page reload
watch(moduleIsReady, () => setup())

// Text to display UI for the current video time and total
const playerTime = computed(() => {
  const currentTime = formatTime(state.currentPlayerTime, state.trim[0])
  const totalTime = formatTime(state.trim[1], state.trim[0])
  return `${currentTime} / ${totalTime}`
})

const scrubberMax = computed(() => {
  const duration = state.duration || 0
  return state.trim[1] || duration
})

const scrubberMin = computed(() => {
  return state.trim[0] || 0
})

const phases = computed(() => {
  if (selectedSession.value) {
    const phases: Phase[] = []
    const segmentsWithPhases = selectedSession.value.segments.filter((s) =>
      s.entries.some((e) => e.category === SegmentCode.phase)
    )
    segmentsWithPhases
      .filter((s) => s.start >= state.trim[0] && s.end <= state.trim[1])
      .forEach((s, i) => {
        const phaseEntry = s.entries.find((e) => e.category == SegmentCode.phase)
        if (phaseEntry)
          phases.push({
            key: `phase-key-${i}`,
            start: s.start,
            end: s.end,
            text: phaseEntry.text
          })
      })
    return phases
  } else return []
})

const highlights = computed(() => {
  if (selectedSession.value) {
    const highlights: Highlight[] = []
    const segmentsWithHighlights = selectedSession.value.segments.filter((s) =>
      s.entries.some((e) => e.category === SegmentCode.highlight)
    )
    segmentsWithHighlights
      .filter((s) => s.start >= state.trim[0] && s.end <= state.trim[1])
      .forEach((s, i) => {
        const hEntry = s.entries.find((e) => e.category == SegmentCode.highlight) // Assuming only one for the current time
        if (hEntry)
          highlights.push({
            key: `highlight-key-${i}`,
            start: s.start,
            text: hEntry.text
          })
      })
    return highlights
  } else return []
})

function calculatePhaseSize(phase: Phase) {
  // Width
  const durationSeconds = phase.end - phase.start
  const totalTrimmedDuration = state.trim[1] - state.trim[0]
  const widthPercent = (durationSeconds / totalTrimmedDuration) * 100

  // X
  const relativePhaseStart = phase.start - state.trim[0]
  const x = (relativePhaseStart / state.trim[1]) * 100
  return `width: ${widthPercent}%; left: ${x}%;`
}

function calculateHighlightLocation(h: Highlight) {
  // X
  const relativePhaseStart = h.start - state.trim[0]
  const x = (relativePhaseStart / state.trim[1]) * 100
  return `left: ${x}%;`
}

function setPlayerHeight() {
  setTimeout(() => {
    state.playerHeight = videoElement.value?.getBoundingClientRect().height || 300
  }, 600)
}

// Event listener for UI volume adjustment
function adjustVolume(level: number): void {
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (audioPlayer) {
    audioPlayer.volume = level
  }
}

async function adjustProgress(value: number): Promise<void> {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    videoPlayer.currentTime = value
    audioPlayer.currentTime = value
    if (!state.playing) {
      state.currentPlayerTime = value
      emit('timeupdate', value)
    }
    //audioPlayer.stop()
    //await audioPlayer.play(value)
  }
}

// Event listener for UI trim adjustment
// Trim value is only emitted upon 'save'. See 'confirmTrim()'
async function adjustTrim(newValue: number[]): Promise<void> {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  emit('trimupdate', newValue)
  if (videoPlayer && audioPlayer) {
    state.trim = newValue
    if (state.currentPlayerTime > newValue[1]) {
      videoPlayer.currentTime = newValue[1]
      audioPlayer.currentTime = newValue[1]
      state.currentPlayerTime = newValue[1]
    }
    if (state.currentPlayerTime < newValue[0]) {
      videoPlayer.currentTime = newValue[0]
      audioPlayer.currentTime = newValue[0]
      state.currentPlayerTime = newValue[0]
    }
  }
}

function toggleScreenMode(): void {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  if (videoPlayer && !state.fullScreenMode) videoPlayer.requestFullscreen()
  else document.exitFullscreen()
  // fullScreenMode.value = !fullScreenMode.value
}

function onTimeUpdate() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  if (videoPlayer) {
    emit('timeupdate', videoPlayer.currentTime) // emit current time event
    if (videoPlayer.currentTime >= state.trim[1]) {
      stopPlaying()
    } else {
      if (!state.pauseUpdates) state.currentPlayerTime = videoPlayer.currentTime
    }
  }
}

async function startPlaying() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer && selectedSession.value) {
    state.playing = true
    videoPlayer.addEventListener('timeupdate', onTimeUpdate)
    await videoPlayer.play()
    videoPlayer.currentTime = state.currentPlayerTime
    audioPlayer.play()
    audioPlayer.currentTime = state.currentPlayerTime
    audioPlayer.volume = state.currentVolume
  }
}

function stopPlaying() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    videoPlayer.pause()
    audioPlayer.pause()
    state.playing = false
    videoPlayer.currentTime = state.trim[0]
    audioPlayer.currentTime = state.trim[0]
    state.currentPlayerTime = videoPlayer.currentTime
    videoPlayer.removeEventListener('timeupdate', onTimeUpdate)
  }
}

function pausePlaying() {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer) {
    videoPlayer.pause()
    audioPlayer.pause()
    state.playing = false
    videoPlayer.removeEventListener('timeupdate', onTimeUpdate)
  }
}

function editStartTime() {
  state.editingStartTime = true
  nextTick(() => {
    if (endTimeInputRef.value) startTimeInputRef.value.focus()
  })
}
function editEndTime() {
  state.editingEndTime = true
  nextTick(() => {
    if (endTimeInputRef.value) endTimeInputRef.value.focus()
  })
}

// Load the player with video data from a new result
// or reload it from store if reloadingFromStore == true
// reloadingFromStore is true / triggered after the video data has been decrypted
// or after returning to the video from the list
function loadPlayers(): void {
  const videoPlayer: HTMLVideoElement | undefined = videoElement.value
  const audioPlayer: HTMLAudioElement | undefined = audioElement.value
  if (videoPlayer && audioPlayer && selectedSession.value) {
    const dataLoaded = () => {
      state.duration = videoElement.value?.duration || 0
      state.trim[1] = state.duration
      emit('trimupdate', state.trim)
      videoPlayer.removeEventListener('loadeddata', dataLoaded)
    }

    audioPlayer.src = `${serverBaseUrl}/api/file?group=${selectedSession.value.group}&session=${selectedSession.value.session}&module=CameraAudio`

    const videoPath = `${serverBaseUrl}/api/file?group=${selectedSession.value.group}&session=${selectedSession.value.session}&module=CameraVideo`
    videoPlayer.setAttribute('src', videoPath)

    videoPlayer.setAttribute('type', 'video/h264')

    videoPlayer.addEventListener('loadeddata', dataLoaded)
    videoPlayer.addEventListener('ended', stopPlaying, false)
    videoPlayer.addEventListener('error', (error) => {
      console.log(error)
    })

    videoPlayer.load()
    audioPlayer.load()
  }
}

async function jumpToTime(item: Phase | Segment | Highlight): Promise<void> {
  let startTime
  startTime = item.start
  adjustProgress(startTime)
}

// Validate the latest change to time, save if it is correct, show 'error' otherwise
function validateTime() {
  const regex = /^(\d{1}:)?\d{2}:\d{2}$/
  const startTimeSeconds = formattedTimeToSeconds(state.localStartTime)
  const endTimeSeconds = formattedTimeToSeconds(state.localEndTime)

  if (
    state.localStartTime.match(regex) &&
    startTimeSeconds >= 0 &&
    startTimeSeconds <= state.trim[1]
  ) {
    state.incorrectStartTime = false
    state.trim[0] = startTimeSeconds
  } else state.incorrectStartTime = true

  if (
    state.localEndTime === '' ||
    (state.localEndTime.match(regex) &&
      endTimeSeconds > 0 &&
      endTimeSeconds <= state.trim[1] &&
      endTimeSeconds > startTimeSeconds)
  ) {
    state.incorrectEndTime = false
    if (state.localEndTime !== '') state.trim[1] = endTimeSeconds
  } else state.incorrectEndTime = true
  state.editingEndTime = false
  state.editingStartTime = false
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
.volume-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 8px;
  --slider-vertical-height: 4rem;
  --slider-bg: #444;
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.volume-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}
/* .volume-slider ::v-deep(.slider-handle) {
  right: calc(
    var(--slider-handle-height, 16px) / 2 * -1 - var(--slider-height, 6px) / 2 * -1
  ) !important;
} */
.trimming-slider {
  --slider-handle-bg: #059fff;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #059fff;
  --slider-tooltip-bg: #059fff;
  --slider-tooltip-font-size: 0.7rem;
  --slider-tooltip-font-weight: 200;
  --slider-tooltip-line-height: 0.5rem;
}
.trimming-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.playing-slider {
  --slider-handle-bg: #2a8805;
  --slider-handle-width: 16px;
  --slider-handle-height: 16px;
  --slider-height: 3px;
  --slider-vertical-height: 4rem;
  --slider-bg: rgba(163, 185, 255, 0.2);
  --slider-connect-bg: #2a8805;
  --slider-tooltip-bg: #2a8805;
}
.playing-slider ::v-deep(.slider-handle-upper) {
  --slider-tooltip-line-height: 2rem !important;
}

.layout {
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.playbackVideo {
  margin: 0;
  width: 100%;
  background-color: green;
  z-index: 1;
}
.playbackVideoSmall {
  margin: auto;
  width: 80%;
  background-color: green;
  z-index: 1;
}
/* video::-webkit-media-controls-enclosure {
  display: none !important;
} */
#videoContainer {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

#segments {
  height: 50%;
}

.highlight-tooltip {
  background: var(--slider-tooltip-bg, #10b981);
  border: 1px solid var(--slider-tooltip-bg, #10b981);
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 10px);
  min-height: var(--slider-tooltip-min-height, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.highlight-tooltip span {
  display: none;
}

.highlight-tooltip:hover {
  background: var(--slider-tooltip-bg, #10b981);
  border: 1px solid var(--slider-tooltip-bg, #10b981);
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.highlight-tooltip:hover span {
  display: block;
}

.highlight-horizontal .highlight-tooltip-top {
  bottom: calc(
    var(--slider-handle-height, 16px) + var(--slider-tooltip-arrow-size, 5px) +
      var(--slider-tooltip-distance, 3px)
  );
  left: 50%;
  transform: translate(-50%);
}

.highlight-horizontal .highlight-tooltip-top:before {
  border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
  border-top-color: inherit;
  bottom: calc(var(--slider-tooltip-arrow-size, 5px) * -2);
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 0;
}
</style>
