<template>
  <div class="animate-spin h-11 w-11 loader rounded-full"></div>
  <Notice class="mt-11" :text="t('processing')" :type="DialogMessageType.Warning" />
</template>
<script setup lang="ts">
import { defineEmits, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { DialogMessageType } from '@/interfaces.js'

import Notice from '@/components/Notice.vue'

const emit = defineEmits<{
  (e: 'setLoadingDone'): void
}>()

const messages = {
  en: {
    processing: 'Your resource is still processing.'
  },
  no: {
    processing: 'Your resource is still processing.'
  }
}

const { t } = useI18n({ messages })

onMounted(() => {
  setTimeout(() => {
    emit('setLoadingDone')
  }, 4000)
})
</script>
<style scoped>
.loader {
  border: 6px solid white;
  border-top: 6px solid gainsboro;
}
</style>
