<template>
  <div>
    <div v-if="props.attribute === 'pipeline'" class="flex justify-center items-center">
      <div
        v-if="props.session.status.pipeline === PipelineStatus.Copying"
        class="w-3 h-3 rounded-full bg-red-500"
      ></div>
      <div
        v-if="props.session.status.pipeline === PipelineStatus.Original"
        class="w-3 h-3 rounded-full bg-orange-500"
      ></div>
      <div
        v-if="props.session.status.pipeline === PipelineStatus.Cleaned"
        class="w-3 h-3 rounded-full bg-yellow-500"
      ></div>
      <!-- TODO: ONCE TRANSCRIPT STATUS IS HERE, WE CAN EDIT -->
      <div
        v-if="props.session.status.pipeline === PipelineStatus.Ready"
        class="w-3 h-3 rounded-full bg-green-500 mx-1"
      ></div>
      <p v-if="$props.text">{{ t($props.session.status.pipeline) }}</p>
    </div>
    <div v-else-if="props.attribute === 'module'">
      <div
        v-if="
          props.session.status.modules[$props.moduleType || ModuleType.Transcript] ===
          PipelineSubStatus.ScriptSlot1
        "
        class="w-3 h-3 rounded-full bg-red-500"
      ></div>
      <div
        v-if="
          props.session.status.modules[$props.moduleType || ModuleType.Transcript] ===
          PipelineSubStatus.ScriptSlot2
        "
        class="w-3 h-3 rounded-full bg-orange-500"
      ></div>
      <div
        v-if="
          props.session.status.modules[$props.moduleType || ModuleType.Transcript] ===
          PipelineSubStatus.ScriptSlot3
        "
        class="w-3 h-3 rounded-full bg-yellow-500"
      ></div>
      <div
        v-if="
          props.session.status.modules[$props.moduleType || ModuleType.Transcript] ===
          PipelineSubStatus.Ready
        "
        class="w-3 h-3 rounded-full bg-green-500"
      ></div>
    </div>
    <div v-else>{{ t('nosuchmoduletype') }}</div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { useI18n } from 'vue-i18n'

import { ModuleType, PipelineStatus, PipelineSubStatus } from '@models'
import type { Session } from '@frontend'

/*
  Copying = 'Copying',
  Original = 'Original',
  Cleaned = 'Cleaned',
  Ready = 'Ready'
  */

const { t } = useI18n()

const props = defineProps({
  session: { type: Object as PropType<Session>, required: true },
  attribute: { type: String, required: true },
  moduleType: { type: String as PropType<ModuleType>, default: ModuleType.Transcript },
  text: { type: Boolean, default: false }
})
</script>

<style></style>
