import { type ComputedRef, computed, reactive } from 'vue'
import { type ExtendedStoreResponse, type StoreResponse } from '@/models/index.js'
import type { Group } from '@frontend'
import type { Session } from '@frontend'
import useApiService from '@/api/apiRequest.js'
import type { Segment } from '@database'

const apiService = useApiService()

// TODO: define some generic class / interface for that
interface Visualisation {}
type EditableSegment = Segment & { index: number }

enum FileState {
  Default = 'Default',
  Processing = 'Processing',
  Done = 'Done'
}

// TODO: outsource ProcessingState to common type repo on the server end...
interface State {
  visualisations: Visualisation[] | undefined
  fileStates: Record<string, FileState> // map of visualisation ids and their state
  selectedGroup: Group | undefined
  selectedSession: Session | undefined
}

const state: State = reactive({
  visualisations: undefined,
  fileStates: {},
  selectedGroup: undefined,
  selectedSession: undefined
})

interface Actions {
  setSelectedGroup: (group: Group) => void
  setSelectedSession: (session: Session) => void
  getVisualisationsForSession: (id: string) => Promise<StoreResponse>
  retriggerForSession: (id: string) => Promise<StoreResponse>
  checkFileStatus: (id: string) => Promise<ExtendedStoreResponse<FileState>>
  updateSegments: (segments: Segment[]) => Promise<StoreResponse>
  insertSegment: (
    atIndex: number,
    replace: boolean,
    segment?: EditableSegment
  ) => EditableSegment | undefined
}

interface Getters {
  selectedGroup: ComputedRef<Group | undefined>
  selectedSession: ComputedRef<Session | undefined>
  visualisations: ComputedRef<Visualisation[] | undefined>
  fileState: ComputedRef<Record<string, FileState>>
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useVisualisationStore(): ServiceInterface {
  const actions = {
    setSelectedGroup(group: Group): void {
      state.selectedGroup = group
    },

    setSelectedSession(session: Session): void {
      state.selectedSession = session
    },

    async getVisualisationsForSession(id: string): Promise<StoreResponse> {
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
      } else {
        console.log(res.data)
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    // re-triggers the processing of visualisations for a session
    async retriggerForSession(id: string): Promise<StoreResponse> {
      // TODO: figure out how to trigger the correct processing? Is this mapped in the db and the server takes care of it?
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
      } else {
        console.log(res.data)
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    // Inserts at given index and returns the index of the inserted segment
    // If replace == true, delete the existing segment before inserting (can be used to update a segment)
    // If segment is undefined and replace == true, just delete the given index
    insertSegment(
      atIndex: number,
      replace = false,
      segment?: EditableSegment
    ): EditableSegment | undefined {
      if (state.selectedSession) {
        if (segment) {
          state.selectedSession.segments.splice(atIndex, replace ? 1 : 0, segment)
          segment.index = atIndex
          return segment
        } else {
          state.selectedSession.segments.splice(atIndex, 1)
        }
      }
    },

    async updateSegments(segments: Segment[]): Promise<StoreResponse> {
      if (state.selectedSession) {
        const res = await apiService.request.updateSegments(state.selectedSession.id, segments)
        if ((res.errors && res.errors.length > 0) || !res.data) {
          const error = 'Segment update query contains no records or invalid session'
          console.error(res.errors ? res.errors : error)
        } else {
          const segs = Object.values(res.data) as Segment[]
          state.selectedSession.segments = segs
          return { success: true }
        }
        return { success: false, error: res.errors }
      }
      return { success: false, error: new Error('No session selected') }
    },

    async checkFileStatus(id: string): Promise<ExtendedStoreResponse<FileState>> {
      /*
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
      } else {
        const result = res.data as string
        const fileState: FileState = FileState[result]
        return { success: true, data: fileState }
      }
        */
      return { success: false, data: FileState.Default, error: [] }
    }
  }

  const getters = {
    get selectedGroup(): ComputedRef<Group | undefined> {
      return computed(() => state.selectedGroup)
    },

    get selectedSession(): ComputedRef<Session | undefined> {
      return computed(() => state.selectedSession)
    },

    get visualisations(): ComputedRef<Visualisation[] | undefined> {
      return computed(() => state.visualisations)
    },

    get fileState(): ComputedRef<Record<string, FileState>> {
      return computed(() => state.fileStates)
    }
  }

  return { state, actions, getters }
}

export { useVisualisationStore }
