import { type ComputedRef, computed, reactive } from 'vue'
import { User, type StoreResponse } from '@/models/index.js'
import type { Group } from '@frontend'
import useApiService from '@/api/apiRequest.js'

const apiService = useApiService()

interface State {
  user: User | undefined
  isRegistered: boolean
  groups: Group[] | undefined
}

const state: State = reactive({
  user: undefined,
  isRegistered: false,
  groups: undefined
})

interface Actions {
  reset: () => void
  logout: () => void
  getUserDetails: () => Promise<StoreResponse>
  isValidSession: () => Promise<boolean>
  getGroupsForUser: () => Promise<StoreResponse>
  test: () => Promise<StoreResponse>
}

interface Getters {
  user: ComputedRef<User | undefined>
  groups: ComputedRef<Group[] | undefined>
}

interface ServiceInterface {
  state: State
  actions: Actions
  getters: Getters
}

function useUserStore(): ServiceInterface {
  const actions = {
    reset(): void {
      state.user = undefined
      state.isRegistered = false
    },

    async getUserDetails(): Promise<StoreResponse> {
      const res = await apiService.request.fetchUserDetails()
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
        actions.reset() // reset user store to initial state
      } else {
        state.user = new User(res.data)
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    async isValidSession(): Promise<boolean> {
      const res = await actions.getUserDetails()
      if (!res.success) {
        this.reset() // reset user store to initial state
        return false
      }
      return true
    },

    logout(): void {
      /*
      apiService.request.logout().then((logoutResponse) => {
        const url = logoutResponse.data?.redirectURI
        if (url) window.open(url, '_blank', '_self')
      })
      */
      this.reset() // reset values whenever logout is invoked
    },

    async getGroupsForUser(): Promise<StoreResponse> {
      const res = await apiService.request.getGroups()
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
        actions.reset() // reset user store to initial state
      } else {
        const rows = Object.values(res.data) as Group[]
        state.groups = rows
        return { success: true }
      }
      return { success: false, error: res.errors }
    },

    async test(): Promise<StoreResponse> {
      const res = await apiService.request.test()
      console.log(res)
      if ((res.errors && res.errors.length > 0) || !res.data) {
        const error = 'User query contains no records or invalid session'
        console.error(res.errors ? res.errors : error)
        actions.reset() // reset user store to initial state
      } else {
        console.log(res.data)
        return { success: true }
      }
      return { success: false, error: res.errors }
    }
  }

  const getters = {
    get user(): ComputedRef<User | undefined> {
      return computed(() => state.user)
    },

    get groups(): ComputedRef<Group[] | undefined> {
      return computed(() => state.groups)
    }
  }

  return { state, actions, getters }
}

export { useUserStore }
