<template>
  <div class="flex items-center justify-between px-3 py-4 rounded-md my-2" :class="noticeStyle()">
    <div class="w-1/6 flex justify-center">
      <p
        class="bg-white font-extrabold rounded-full w-5 h-5 flex justify-center items-center mr-3 text-black"
      >
        !
      </p>
    </div>
    <p class="text-left w-5/6 text-sm text-white m-1">{{ props.text }}</p>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { defineProps } from 'vue'
import { DialogMessageType } from '@/interfaces.js'

const props = defineProps({
  type: { type: String as PropType<DialogMessageType>, required: true },
  text: { type: String, required: true }
})

function noticeStyle() {
  switch (props.type) {
    case DialogMessageType.Information:
      return 'dialog-info'
    case DialogMessageType.Success:
      return 'dialog-success'
    case DialogMessageType.Warning:
      return 'dialog-warn'
    case DialogMessageType.Error:
      return 'dialog-err'
  }
}
</script>

<style scoped></style>
