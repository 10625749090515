<template>
  <div class="mx-5 bg-gray-100">
    <p class="text-xl font-bold ml-4 text-black w-48 my-2">Stress Management</p>
    <div class="flex flex-row text-black justify-around">
      <div class="flex flex-row items-center">
        <input type="checkbox" id="S1" value="S1" v-model="checkedNames" @change="setupChart" />
        <label class="ml-2" for="S1">Student 1</label>
      </div>
      <div class="flex flex-row items-center">
        <input type="checkbox" id="S2" value="S2" v-model="checkedNames" @change="setupChart" />
        <label class="ml-2" for="S2">Student 2</label>
      </div>
      <div class="flex flex-row items-center">
        <input type="checkbox" id="S3" value="S3" v-model="checkedNames" @change="setupChart" />
        <label class="ml-2" for="S3">Student 3</label>
      </div>
      <div class="flex flex-row items-center">
        <input type="checkbox" id="S4" value="S4" v-model="checkedNames" @change="setupChart" />
        <label class="ml-2" for="S4">Student 4</label>
      </div>
      <div class="flex flex-row items-center">
        <input type="checkbox" id="S5" value="S5" v-model="checkedNames" @change="setupChart" />
        <label class="ml-2" for="S5">Student 5</label>
      </div>
      <div class="flex flex-row items-center">
        <input type="checkbox" id="S6" value="S6" v-model="checkedNames" @change="setupChart" />
        <label class="ml-2" for="S6">Student 6</label>
      </div>
    </div>
    <div class="flex flex-row items-center text-black mt-5 ml-16">
      <input type="checkbox" id="phase" :value="true" v-model="showPhases" @change="setupChart" />
      <label class="ml-2" for="phase">Phases</label>
    </div>
    <div class="flex flex-col justify-center">
      <div class="p-8 px-16" id="accelerationChart"></div>
      <div class="p-8 px-16" id="heartChart"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch, type PropType } from 'vue'
import * as d3 from 'd3'
import * as d3Collection from 'd3-collection'
import { useVisualisationStore } from '@/composition/visualisationStore'
import { serverBaseUrl, type Phase } from '@/constants'
import { SegmentCode } from '@models'
// import type { Segment, SegmentEntry } from '@database'
// import { SegmentCode } from '@models'

type ColumnNames =
  | 'Time'
  | 'S1 - HR'
  | 'S2 - HR'
  | 'S3 - HR'
  | 'S4 - HR'
  | 'S5 - HR'
  | 'S6 - HR'
  | 'S1 - Mag'
  | 'S2 - Mag'
  | 'S3 - Mag'
  | 'S4 - Mag'
  | 'S5 - Mag'
  | 'S6 - Mag'

type ParsedRow = {
  name: string
  x: number
  y: number
}

const props = defineProps({
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true }
})

const { time, trim } = toRefs(props)
const visualisationStore = useVisualisationStore()
const session = visualisationStore.getters.selectedSession

const checkedNames = ref([])
const showPhases = ref(false)

let data: d3.DSVParsedArray<Record<ColumnNames, number>>

const width = 1024
const height = 768
const columnNames = [
  'S1 - HR',
  'S2 - HR',
  'S3 - HR',
  'S4 - HR',
  'S5 - HR',
  'S6 - HR',
  'S1 - Mag',
  'S2 - Mag',
  'S3 - Mag',
  'S4 - Mag',
  'S5 - Mag',
  'S6 - Mag'
]

watch(
  () => session.value,
  async () => {
    await callData()
    setupChart()
  }
)

watch(
  () => trim.value,
  () => setupChart()
)

const phases = computed(() => {
  if (session.value) {
    const phases: Phase[] = []
    const segmentsWithPhases = session.value.segments.filter((s) =>
      s.entries.some((e) => e.category === SegmentCode.phase)
    )
    segmentsWithPhases
      .filter((s) => s.start >= trim.value[0] && s.end <= trim.value[1])
      .forEach((s, i) => {
        const phaseEntry = s.entries.find((e) => e.category == SegmentCode.phase)
        if (phaseEntry)
          phases.push({
            key: `phase-key-${i}`,
            start: s.start,
            end: s.end,
            text: phaseEntry.text
          })
      })
    return phases
  } else return []
})

async function callData() {
  if (session.value) {
    function parseRow(d: Record<ColumnNames, string>) {
      let newRow: Record<ColumnNames, number> = {
        Time: parseInt(d['Time']),
        'S1 - HR': parseFloat(d['S1 - HR']),
        'S1 - Mag': parseFloat(d['S1 - Mag']),
        'S2 - HR': parseFloat(d['S2 - HR']),
        'S2 - Mag': parseFloat(d['S2 - Mag']),
        'S3 - HR': parseFloat(d['S3 - HR']),
        'S3 - Mag': parseFloat(d['S3 - Mag']),
        'S4 - HR': parseFloat(d['S4 - HR']),
        'S4 - Mag': parseFloat(d['S4 - Mag']),
        'S5 - Mag': parseFloat(d['S5 - Mag']),
        'S5 - HR': parseFloat(d['S5 - HR']),
        'S6 - HR': parseFloat(d['S6 - HR']),
        'S6 - Mag': parseFloat(d['S6 - Mag'])
      }
      return newRow
    }
    const s = session.value
    data = await d3.csv(
      `${serverBaseUrl}/api/file?group=${s.group}&session=${s.session}&module=StressManagement`,
      {
        headers: {
          'Content-Type': 'text/csv',
          credentials: 'include'
        }
      },
      parseRow
    )
  }
}

async function setupChart() {
  if (session.value) {
    const accelData: ParsedRow[] = []
    const heartData: ParsedRow[] = []
    const filteredColumnNames: string[] = columnNames.filter((name) => {
      return checkedNames.value.some((cn: string) => name.includes(cn))
    })

    data.forEach((row) => {
      filteredColumnNames.forEach((name) => {
        const newNesting: ParsedRow = {
          name,
          x: row.Time,
          y: row[name as ColumnNames]
        }
        if (!isNaN(newNesting.y)) {
          if (name.includes('- Mag')) accelData.push(newNesting)
          else heartData.push(newNesting)
        }
      })
    })

    let colors = ['black', 'Blue', 'red', 'lime', 'magenta', 'tan', 'DarkOrange']

    const xExtentData = trim.value as [number, number]
    /* const xExtentData = d3.extent(data, function (d) {
      return d.Time
    }) as [number, number] */
    //let xScale = d3.scaleLinear().domain(xExtentData).range([0, width])
    //let yScale = d3.scaleLinear().domain([0, 200]).range([height, 0])

    let accelScaleX = d3.scaleLinear().domain(xExtentData).range([0, width])
    let accelScaleY = d3.scaleLinear().domain([900, 1100]).range([height, 0])

    let heartScaleX = d3.scaleLinear().domain(xExtentData).range([0, width])
    let heartScaleY = d3.scaleLinear().domain([50, 120]).range([height, 0])

    d3.selectAll('svg').remove()

    const accelSvg = d3
      .select('#accelerationChart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;')

    const heartSvg = d3
      .select('#heartChart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; overflow: visible; font: 10px sans-serif;')

    // X Axis
    accelSvg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .style('color', 'black')
      .call(d3.axisBottom(accelScaleX).tickFormat(d3.format('.0f')))

    heartSvg
      .append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .style('color', 'black')
      .call(d3.axisBottom(heartScaleX).tickFormat(d3.format('.0f')))

    // Y Axis
    accelSvg.append('g').call(d3.axisLeft(accelScaleY)).style('color', 'black')
    heartSvg.append('g').call(d3.axisLeft(heartScaleY)).style('color', 'black')

    // X Axis labels
    accelSvg
      .append('text')
      .attr('transform', 'translate(' + width / 2 + ' ,' + (height + 30) + ')')
      .style('text-anchor', 'middle')
      .text('Time')
    heartSvg
      .append('text')
      .attr('transform', 'translate(' + width / 2 + ' ,' + (height + 30) + ')')
      .style('text-anchor', 'middle')
      .text('Time')

    // Y Axis labels
    accelSvg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - 50)
      .attr('x', 0 - height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Acceleration Magnitude')
    heartSvg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - 50)
      .attr('x', 0 - height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Heart Rate (BPM)')

    const lineGenerator = d3
      .line<ParsedRow>()
      .x(function (d: ParsedRow) {
        const scale = d.name.includes('- HR') ? heartScaleX(d.x) : accelScaleX(d.x)
        if (isNaN(scale)) return 0
        return scale
      })
      .y(function (d) {
        const scale = d.name.includes('- HR') ? heartScaleY(d.y) : accelScaleY(d.y)
        if (isNaN(scale)) return 0
        return scale
      })
      .curve(d3.curveCardinal)

    // Nest each student's data
    const nestedAccelerationData = d3Collection
      .nest<ParsedRow>()
      .key(function (d) {
        return d.name
      })
      .entries(accelData)

    const nestedHeartrateData = d3Collection
      .nest<ParsedRow>()
      .key(function (d) {
        return d.name
      })
      .entries(heartData)

    if (showPhases.value) {
      accelSvg.selectAll('.phaseBox').remove()
      phases.value.forEach((phase) => {
        const w = accelScaleX(phase.end - phase.start)
        const x = accelScaleX(phase.start)
        // Phase Box
        accelSvg
          .append('rect')
          .attr('class', 'phaseBox')
          .attr('width', w)
          .attr('height', height)
          .attr('x', x)
          .attr('y', 0)
          .attr('fill', '#FFFACD')
          .attr('stroke-dasharray', [0, w, height, 0])
          .style('stroke', 'gold')

        accelSvg
          .append('text')
          .attr('x', x + 10)
          .attr('y', 10)
          .attr('dy', '.35em')
          .text(phase.text)

        heartSvg
          .append('rect')
          .attr('class', 'phaseBox')
          .attr('width', w)
          .attr('height', height)
          .attr('x', x)
          .attr('y', 0)
          .attr('fill', '#FFFACD')
          .attr('stroke-dasharray', [0, w, height, 0])
          .style('stroke', 'gold')

        heartSvg
          .append('text')
          .attr('x', x + 10)
          .attr('y', 10)
          .attr('dy', '.35em')
          .text(phase.text)
      })
    }

    let aLines = accelSvg.append('g')
    let hLines = heartSvg.append('g')

    // Data plot
    aLines
      .selectAll('.line')
      .data(nestedAccelerationData)
      .enter()
      .append('g')
      .append('path')
      .attr('class', 'line')
      .attr('d', function (d, i) {
        return lineGenerator(d.values)
      })
      .style('fill', 'none')
      .style('stroke', function (d, i) {
        const cKey = parseInt(d.key.charAt(1))
        return colors[cKey]
      })
      .style('opacity', 0.9)
      .style('stroke-width', 2)
      .on('mouseover', function (d, i) {
        d3.select(this).style('stroke-width', 4)
      })
      .on('mouseout', function (d, i) {
        d3.select(this).style('stroke-width', 2)
      })

    hLines
      .selectAll('.line')
      .data(nestedHeartrateData)
      .enter()
      .append('g')
      .append('path')
      .attr('class', 'line')
      .attr('d', function (d, i) {
        return lineGenerator(d.values)
      })
      .style('fill', 'none')
      .style('stroke', function (d, i) {
        const cKey = parseInt(d.key.charAt(1))
        return colors[cKey]
      })
      .style('opacity', 0.9)
      .style('stroke-width', 2)
      .on('mouseover', function (d, i) {
        d3.select(this).style('stroke-width', 4)
      })
      .on('mouseout', function (d, i) {
        d3.select(this).style('stroke-width', 2)
      })

    // Label plot
    aLines
      .selectAll('.labels')
      .data(nestedAccelerationData)
      .enter()
      .append('g')
      .append('text')
      .attr('x', function (d, i) {
        let hori = d.values[d.values.length - 1]['x']
        return accelScaleX(hori) + 5
      })
      .attr('y', function (d, i) {
        let vert = d.values[d.values.length - 1]['y']
        return accelScaleY(vert) + 5
      })
      .attr('font-size', '16px')
      .text(function (d, i) {
        console.log(d)
        return d.key
      })
      .style('fill', function (d, i) {
        const cKey = parseInt(d.key.charAt(1))
        return colors[cKey]
      })
      .attr('opacity', 1)

    hLines
      .selectAll('.labels')
      .data(nestedHeartrateData)
      .enter()
      .append('g')
      .append('text')
      .attr('x', function (d, i) {
        let hori = d.values[d.values.length - 1]['x']
        return heartScaleX(hori) + 5
      })
      .attr('y', function (d, i) {
        let vert = d.values[d.values.length - 1]['y']
        if (isNaN(vert)) {
          console.log(vert)
        }
        return heartScaleY(vert) + 5
      })
      .attr('font-size', '16px')
      .text(function (d, i) {
        console.log(d)
        return d.key
      })
      .style('fill', function (d, i) {
        const cKey = parseInt(d.key.charAt(1))
        return colors[cKey]
      })
      .attr('opacity', 1)

    const drawValueLine = () => {
      accelSvg.selectAll('.valueLine').remove()
      // Value line
      accelSvg
        .append('line') // attach a line
        .attr('class', 'valueLine')
        .style('stroke', 'black') // colour the line
        .attr('x1', accelScaleX(time.value)) // x position of the first end of the line
        .attr('y1', accelScaleY(0)) // y position of the first end of the line
        .attr('x2', accelScaleX(time.value)) // x position of the second end of the line
        .attr('y2', accelScaleY(1100))
    }

    watch(
      () => time.value,
      () => drawValueLine()
    )
  }
}
</script>
