const HttpStatusCodes = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER: 500
}

enum MIMEType {
  html = 'text/html',
  txt = 'text/plain',
  css = 'text/css',
  gif = 'image/gif',
  jpg = 'image/jpeg',
  png = 'image/png',
  svg = 'image/svg+xml',
  js = 'application/javascript'
}

const smsResponses: Record<string, Record<string, string>> = {
  en: {
    OTCText: 'Welcome. Your one-time code is: '
  },
  no: {
    OTCText: 'Velkommen. Din engangskode er: '
  }
}

export enum FileCategory {
  AmbientVideo = 'AmbientVideo',
  PersonalAudio = 'PersonalAudio',
  AmbientAudio = 'AmbientAudio',
  AudioTranscript = 'AudioTranscript',
  ValidatedAudioTranscript = 'ValidatedAudioTranscript',
  Log = 'Log'
}

export enum ModuleType {
  CameraVideo = 'CameraVideo',
  CameraAudio = 'CameraAudio',
  Transcript = 'Transcript',
  StressManagement = 'StressManagement'
}

export enum PipelineStatus {
  None = 'None',
  Copying = 'Copying',
  Original = 'Original',
  Cleaned = 'Cleaned',
  Ready = 'Ready'
}

export enum PipelineSubStatus {
  ScriptSlot1 = 'ScriptSlot1',
  ScriptSlot2 = 'ScriptSlot2',
  ScriptSlot3 = 'ScriptSlot3',
  Ready = 'Ready'
}

export enum SegmentCode {
  subtitle = 'subtitle',
  highlight = 'highlight',
  phase = 'phase',
  start = 'start',
  end = 'end'
}

export enum FileExtension {
  txt = 'txt',
  mov = 'mov',
  aiff = 'aiff'
}

export { MIMEType, HttpStatusCodes, smsResponses }
