<template>
  <div class="flex flex-col h-full w-full">
    <div class="flex flex-row justify-start overflow-hidden">
      <h3 class="text-xl font-bold p-2">{{ t('segments') }}</h3>
    </div>
    <div id="segments" class="overflow-scroll scroll-smooth h-full w-full overflow-x-hidden">
      <div
        v-for="segment in state.segments"
        ref="segmentContainer"
        :id="`segment-${segment.index}`"
        :key="`segment-${segment.index}`"
        class="mt-7 rounded p-2 scroll-my-4"
        :class="segmentStyles(segment.index)"
      >
        <div v-for="(entry, index) in segment.entries" :key="index">
          <SegmentTypeIcon :entry="entry" />
          <span v-if="entry.category === SegmentCode.subtitle" class="font-bold text-green-900"
            >{{ t('person') }} {{ entry.speaker }}:
          </span>
          <span class="font-italic text-green-900">{{ entry.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import scrollIntoView from 'scroll-into-view-if-needed'
import { SegmentCode } from '@models'
import type { Segment, SegmentEntry } from '@database'
import type { Phase } from '@/constants'
import { useVisualisationStore } from '@/composition/visualisationStore'
import SegmentTypeIcon from './SegmentTypeIcon.vue'

type IndexedSegment = Segment & { index: number; hasSubtitle: boolean }

const messages = {
  no: {},
  en: {}
}

const props = defineProps({
  time: { type: Number, required: true },
  trim: { type: Object as PropType<number[]>, required: true }
})

const state = reactive({
  duration: 0,
  phases: [] as Phase[],
  segments: [] as IndexedSegment[],
  playerHeight: 300,
  highlightedSegmentIndex: -1
})

const { t } = useI18n({ messages })
const visualisationStore = useVisualisationStore()
const selectedSession = visualisationStore.getters.selectedSession
const segmentContainer = ref()

watch(
  () => props.time,
  (newTime) => moveToSegment(newTime)
)

watch(selectedSession, () => addTrackDetails())

onMounted(() => {
  addTrackDetails()
})

function moveToSegment(newTime: number) {
  // Assumes segments are sorted by startTime
  const segment = state.segments
    .filter((s) => s.hasSubtitle)
    .find((s) => newTime >= s.start && newTime <= s.end)
  if (segment && segmentContainer.value) {
    state.highlightedSegmentIndex = segment.index
    const id = `segment-${segment.index}`
    const segmentElement = document.getElementById(id)
    const segmentContainer = document.getElementById('segments')
    if (segmentElement && segmentContainer) {
      scrollIntoView(segmentElement, {
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
        boundary: segmentContainer
      })
    }
  }
}

function addTrackDetails() {
  state.phases = []
  state.segments = []
  if (selectedSession.value) {
    selectedSession.value.segments.forEach((segment, index) => {
      let entries: SegmentEntry[] = []
      let i = 0
      let hasSubtitle = false
      for (const entry of segment.entries) {
        if (entry.category === SegmentCode.phase) {
          state.phases.push({
            key: `key-${i}`,
            start: segment.start,
            end: segment.end,
            text: entry.text.replace('Chapter ', '')
          })
          i++
        }
        if (entry.category === SegmentCode.subtitle) hasSubtitle = true
        entries.push({
          category: entry.category,
          text: entry.text,
          speaker: entry.speaker
        })
      }
      state.segments.push({
        index,
        start: segment.start,
        end: segment.end,
        hasSubtitle,
        entries,
        embeddings: new Map<string, unknown>()
      })
    })
  }
}

function segmentStyles(index: number): string {
  return index === state.highlightedSegmentIndex ? `bg-green-100 opacity-100` : 'opacity-50'
}
</script>
