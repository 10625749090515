<template>
  <div class="p-5 bg-gray-100 text-black flex flex-col mx-5 mb-4">
    <div class="flex flex-row items-center">
      <div
        class="flex items-center justify-center w-10 h-10 p-3 rounded-full border-black border-opacity-40 border-2 transform transition duration-500"
        :class="{ 'rotate-180': state.openCard }"
        @click.stop="state.openCard = !state.openCard"
      >
        <img :src="arrowTopSVG" alt="arrow-button" />
      </div>
      <h3 class="text-xl font-bold ml-4">{{ t('segments') }}</h3>
    </div>
    <!-- TIME SELECTOR -->
    <div
      class="transition-maxheight duration-500 ease-in-out overflow-x-hidden overflow-y-hidden no-scrollbar"
      :class="[state.openCard ? 'max-h-full' : 'max-h-0']"
    >
      <div class="flex flex-col w-full justify-between items-center my-2">
        <div class="flex overflow-x-auto w-full my-3">
          <div
            v-for="(segment, index) in segmentsForTimeslot"
            :key="index"
            class="m-4 border-2 py-4 px-3 rounded cursor-pointer min-w-48"
            @click="setEditSegment(segment)"
            :class="
              editSegment?.index === segment.index ? 'bg-green-200 border-green-500' : 'bg-white'
            "
          >
            <div v-for="(entry, index) in segment.entries" :key="index">
              <SegmentTypeIcon :entry="entry" />
              <span v-if="entry.category === SegmentCode.subtitle" class="font-bold text-green-900"
                >{{ t('person') }} {{ entry.speaker }}: </span
              ><br />
              <span class="font-italic text-green-900">{{ entry.text }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- ACTION BAR -->
      <div class="flex w-full justify-evenly bg-gray-300 p-4">
        <div
          v-if="editSegment"
          disabled
          class="bg-purple-500 mr-2 rounded cursor-pointer"
          @click="splitSegment()"
        >
          <p class="text-white py-px px-4">{{ t('split') }}</p>
        </div>
        <div
          v-if="editSegment"
          class="bg-blue-500 mr-2 rounded cursor-pointer"
          @click="mergeSegment()"
        >
          <p class="text-white py-px px-4">{{ t('merge') }}</p>
        </div>
        <div
          v-if="editSegment"
          class="bg-red-700 mr-2 rounded cursor-pointer"
          @click="deleteSegment()"
        >
          <p class="text-white py-px px-4">{{ t('delete') }}</p>
        </div>
        <div
          v-if="editSegment"
          class="bg-yellow-500 mr-2 rounded cursor-pointer"
          @click="insertSegment()"
        >
          <p class="text-white py-px px-4">{{ t('addnew') }}</p>
        </div>
      </div>
      <div class="flex flex-col w-full justify-between items-center my-7" v-if="editSegment">
        <h3 class="text-xl font-bold">{{ t('segmentdetails') }}</h3>
        <div v-if="editSegment" class="flex w-full flex-col mt-3">
          <div class="flex flex-row items-center mb-2">
            <p class="w-24 mr-4">Start</p>
            <input
              v-if="state.editingStartTime"
              ref="startTimeInputRef"
              class="text-black text-xsv w-24 bg-white py-1 px-2"
              :class="[state.incorrectStartTime ? 'text-red-600' : '']"
              v-model="state.localStartTime"
              @keyup="validateTime()"
              @keyup.enter="completeTimeEntry()"
              @blur="completeTimeEntry()"
            />
            <p
              v-else
              class="text-xsv bg-white py-1 px-2 w-24 rounded-md"
              :class="[state.incorrectStartTime ? 'text-red-600' : '']"
              @click="editStartTime()"
            >
              {{ formattedStartTime }}
            </p>
          </div>
          <div class="flex flex-row items-center">
            <p class="w-24 mr-4">End</p>
            <input
              v-show="state.editingEndTime"
              ref="endTimeInputRef"
              class="text-black text-xsv w-24 bg-white py-1 px-2"
              :class="[state.incorrectEndTime ? 'text-red-600' : '']"
              v-model="state.localEndTime"
              @keyup="validateTime()"
              @keyup.enter="completeTimeEntry()"
              @blur="completeTimeEntry()"
            />
            <div
              v-show="!state.editingEndTime"
              class="flex flex-col items-center rounded-md"
              @click="editEndTime()"
            >
              <p
                class="text-xsv bg-white py-1 px-2 w-24 rounded-md"
                :class="[state.incorrectEndTime ? 'text-red-600' : '']"
              >
                {{ formattedEndTime }}
              </p>
            </div>
          </div>
          <hr class="mt-4" />
          <h3 class="text-xl font-bold mt-4">{{ t('segmententries') }}</h3>
          <div
            v-for="(entry, index) in editSegment.entries"
            :key="index"
            class="flex flex-col w-full justify-between my-2"
          >
            <div class="flex flex-row items-center mb-2">
              <p class="w-24 mr-4">Category</p>
              <Multiselect
                :options="Object.values(SegmentCode)"
                :customLabel="(type: string) => type"
                v-model="entry.category"
              ></Multiselect>
            </div>
            <div
              class="flex flex-row items-center mb-2"
              v-if="entry.category === SegmentCode.subtitle"
            >
              <p class="w-24 mr-4">Student</p>
              <Multiselect :options="[1, 2, 3, 4, 5, 6]" v-model="entry.speaker"></Multiselect>
            </div>
            <div class="flex flex-row items-center">
              <p class="w-24 mr-4">Description</p>
              <textarea
                v-model.lazy="entry.text"
                class="w-full p-5 mt-1 rounded-md"
                spellcheck="false"
                autoselect
                :placeholder="t('text')"
              ></textarea>
            </div>
            <div class="flex flex-row justify-end my-2">
              <div class="bg-red-700 w-30 rounded cursor-pointer mt-1" @click="deleteRow(index)">
                <p class="text-white py-px px-4">{{ t('deleterow') }}</p>
              </div>
            </div>
            <hr />
          </div>
          <div class="flex w-full justify-between mt-4">
            <div class="bg-gray-700 mr-2 rounded cursor-pointer" @click="addRow()">
              <p class="text-white py-px px-4">{{ t('addrow') }}</p>
            </div>
            <div class="bg-green-500 mr-2 rounded cursor-pointer" @click="updateSegment()">
              <p class="text-white py-px px-4">{{ t('done') }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          {{ t('nosegment') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  type Ref,
  ref,
  type PropType,
  toRefs,
  type ComputedRef,
  reactive,
  nextTick
} from 'vue'
import { useI18n } from 'vue-i18n'

import SegmentTypeIcon from '@/components/analytics/SegmentTypeIcon.vue'
import arrowTopSVG from '@/assets/icons/svg/arrow_top.svg'
import Multiselect from 'vue-multiselect'
import { useVisualisationStore } from '@/composition/visualisationStore'

import type { Segment, SegmentEntry } from '@database'
import { SegmentCode } from '@models'
import { formattedTimeToSeconds, formatTime } from '@/utilities'

type EditableSegment = Segment & { index: number }

const messages = {
  no: {},
  en: {
    addrow: 'Add Row',
    deleterow: 'Delete Row',
    addnew: 'Add a new segment immediately after the selected one',
    merge: 'Merge the selected segment with the one immediately preceeding it',
    split: 'Copy the selected segment to a new one immediately after',
    delete: 'Delete the selected segment',
    done: 'Save',
    segmentdetails: 'Segment Details',
    segmententries: 'Segment Entries',
    segments: 'Segments'
  }
}

const { t } = useI18n({ messages })

const props = defineProps({
  trim: { type: Object as PropType<number[]>, required: true } // [0,0] start / end of trim selection
})

const { trim } = toRefs(props)

interface State {
  openCard: boolean
  localStartTime: string
  localEndTime: string
  editingEndTime: boolean
  editingStartTime: boolean
  incorrectStartTime: boolean
  incorrectEndTime: boolean
}

const state: State = reactive({
  openCard: false,
  localStartTime: '',
  localEndTime: '',
  editingEndTime: false,
  editingStartTime: false,
  incorrectStartTime: false,
  incorrectEndTime: false
})

const startTimeInputRef = ref()
const endTimeInputRef = ref()
const visualisationStore = useVisualisationStore()
const selectedSession = visualisationStore.getters.selectedSession

const editSegment: Ref<EditableSegment | undefined> = ref()

const segmentsForTimeslot: ComputedRef<EditableSegment[]> = computed(() => {
  const segments = selectedSession.value?.segments || []
  return segments
    .map((s, index) => ({ ...s, index }))
    .filter((segment: Segment) => trim.value[0] <= segment.start && trim.value[1] >= segment.end)
})

const formattedStartTime = computed(() => formatTime(editSegment.value?.start || 0))
const formattedEndTime = computed(() => formatTime(editSegment.value?.end || 0))

// EDITING FEATURES

function setEditSegment(segment: EditableSegment): void {
  editSegment.value = segment
}

function editStartTime() {
  state.editingStartTime = true
  nextTick(() => {
    if (endTimeInputRef.value) startTimeInputRef.value.focus()
  })
}
function editEndTime() {
  state.editingEndTime = true
  nextTick(() => {
    if (endTimeInputRef.value) endTimeInputRef.value.focus()
  })
}

function insertSegment(): void {
  if (editSegment.value) {
    const segment = visualisationStore.actions.insertSegment(editSegment.value.index + 1, false, {
      index: -1,
      start: trim.value[0],
      end: trim.value[1],
      entries: [], // rows, to be added in the UI
      embeddings: new Map<string, unknown>()
    })
    if (segment) setEditSegment(segment)
    addRow()
  }
}

function updateSegment(): void {
  if (editSegment.value) {
    const segment = visualisationStore.actions.insertSegment(
      editSegment.value.index,
      true,
      editSegment.value
    )
    if (segment) saveSession()
  }
}

function deleteSegment(): void {
  if (editSegment.value) {
    visualisationStore.actions.insertSegment(editSegment.value.index, true)
  }
}

function addRow(): void {
  if (editSegment.value) {
    const row: SegmentEntry = {
      category: SegmentCode.highlight,
      speaker: -1,
      text: ''
    }
    editSegment.value.entries.push(row)
  }
}

async function saveSession(): Promise<void> {
  // TODO: add PUT call to API
  if (selectedSession.value) {
    await visualisationStore.actions.updateSegments(selectedSession.value.segments)
    editSegment.value = undefined // reset value
  }
}

function deleteRow(index: number): void {
  if (editSegment.value) {
    editSegment.value.entries.splice(index, 1)
  }
}

// Combine the selected segment with the previous, then update the selected and delete the previous.
function mergeSegment(): void {
  if (selectedSession.value && editSegment.value) {
    const previousIndex = editSegment.value.index - 1
    if (previousIndex > -1) {
      const previousSegment = selectedSession.value.segments[previousIndex]
      editSegment.value.start = previousSegment.start
      editSegment.value.entries = [...previousSegment.entries, ...editSegment.value.entries]

      visualisationStore.actions.insertSegment(editSegment.value.index, true, editSegment.value)
      visualisationStore.actions.insertSegment(previousIndex, true)
      // Note: The above delete operation changed the real index of editSegment!
      editSegment.value.index = previousIndex
    }
  }
}

// Create a new segment that is a copy of the selected one
function splitSegment(): void {
  if (selectedSession.value && editSegment.value) {
    const newSegment = { ...editSegment.value }
    newSegment.entries = editSegment.value.entries.map((e) => e)

    const insertedSegment = visualisationStore.actions.insertSegment(
      editSegment.value.index + 1,
      false,
      editSegment.value
    )
    if (insertedSegment) editSegment.value = insertedSegment
  }
}

// Validate the latest change to time, save if it is correct, show 'error' otherwise
function validateTime() {
  const regex = /^(\d{1}:)?\d{2}:\d{2}$/
  const startTimeSeconds = formattedTimeToSeconds(state.localStartTime)
  const endTimeSeconds = formattedTimeToSeconds(state.localEndTime)

  if (
    editSegment.value &&
    state.localStartTime.match(regex) &&
    startTimeSeconds >= 0 &&
    startTimeSeconds <= trim.value[1]
  ) {
    state.incorrectStartTime = false
    editSegment.value.start = startTimeSeconds
  } else state.incorrectStartTime = true

  if (
    state.localEndTime === '' ||
    (state.localEndTime.match(regex) &&
      endTimeSeconds > 0 &&
      endTimeSeconds <= trim.value[1] &&
      endTimeSeconds > startTimeSeconds)
  ) {
    state.incorrectEndTime = false
    if (state.localEndTime !== '' && editSegment.value) editSegment.value.end = endTimeSeconds
  } else state.incorrectEndTime = true
}

function completeTimeEntry() {
  if (!state.incorrectEndTime && !state.incorrectStartTime) {
    state.editingEndTime = false
    state.editingStartTime = false
  }
}
</script>
