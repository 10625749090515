<template>
  <div class="w-full full-height flex flex-col p-7 bg-gray-800">
    <div class="flex flex-row items-center mb-4">
      <div class="mr-6">
        <router-link
          ref="props.button.buttonLink"
          to="/dashboard"
          class="left-5 top-5 w-10 flex justify-center bg-gray-400 cursor-pointer p-3 m-3 rounded-full"
        >
          <img class="-ml-px w-4 h-4 invert" :src="Arrow" />
        </router-link>
      </div>
      <ComponentHeader name="analytics" />
    </div>
    <div class="flex">
      <div
        v-for="(session, index) in sessions"
        :key="index"
        class="flex items-center text-black p-4 rounded-t mr-px cursor-pointer"
        :class="sessionStyles(session.id)"
        @click="visualisationStore.actions.setSelectedSession(session)"
      >
        <p>{{ t('session') }} {{ index + 1 }}</p>
        <ModuleStatus :session="session" attribute="pipeline" />
      </div>
    </div>
    <div class="flex flex-col w-full h-full overflow-scroll bg-gray-300 rounded-b rounded-tr-lg">
      <VideoModule
        @timeupdate="(time: number) => (state.currentTime = time)"
        @trimupdate="(trim: number[]) => (state.currentTrim = trim)"
      >
        <template v-slot:transcript>
          <TranscriptModule :time="state.currentTime" :trim="state.currentTrim" />
        </template>
      </VideoModule>
      <VideoEditModule :trim="state.currentTrim" />
      <StressManagementChart :time="state.currentTime" :trim="state.currentTrim" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useUserStore } from '@/composition/userStore'
import { useVisualisationStore } from '@/composition/visualisationStore'
import Arrow from '@/assets/icons/angle-left-solid.svg'

import VideoModule from '@/components/analytics/VideoModule.vue'
import VideoEditModule from '@/components/analytics/VideoEditModule.vue'
import ComponentHeader from '@/components/ComponentHeader.vue'
import ModuleStatus from '@/components/analytics/ModuleStatus.vue'
import TranscriptModule from '@/components/analytics/TranscriptModule.vue'
import StressManagementChart from '@/components/charts/StressManagement.vue'

interface State {
  currentTime: number
  currentTrim: number[]
}

const router = useRouter()
const userStore = useUserStore()
const visualisationStore = useVisualisationStore()

const { t } = useI18n()

// TODO: keep track of start, trim length and end here!

const groups = userStore.getters.groups
const selectedGroup = visualisationStore.getters.selectedGroup
const selectedSession = visualisationStore.getters.selectedSession

const state: State = reactive({
  currentTime: 0,
  currentTrim: [0, 0]
})

const sessions = computed(() => {
  return selectedGroup.value?.sessions
})

onMounted(async () => {
  await userStore.actions.getGroupsForUser()
  const groupName = router.currentRoute.value.query.group
  const group = groups.value?.find((group) => group.name === groupName)
  if (groupName && group && group.sessions.length > 0) {
    visualisationStore.actions.setSelectedGroup(group)
    visualisationStore.actions.setSelectedSession(group.sessions[0])
  } else router.push({ name: 'Dashboard' })
  // TODO: trigger getting the files / visualisations from the store
  //await visualisationStore.actions.getVisualisationsForSession(selectedSession)
})

function sessionStyles(id: string) {
  return id === selectedSession.value?.id ? 'bg-gray-300' : 'bg-gray-100'
}
</script>

<style scoped></style>
